<template>
	<div>



		<!-- <h4 class="header-title">List Reason</h4> -->
		<vue-element-loading :active="isLoadingTable" spinner="bar-fade-scale" color="#FF6700" />
		<div class="row">
			<div class="col-md-12">

				<button class="btn btn-create" @click="showFormInput"><i class="mdi mdi-plus-circle mr-1"></i> Create New</button>

			</div>
			<div class="col-md-12">
				<div class="float-lg-right float-left">
					<div class="form-inline">
						<label class="mb-2 mr-sm-2">Search :</label>
						<input v-model="filterText" type="text" class="form-control form-control-sm mb-2 " placeholder="Search . . ." @keyup="doFilter" />
						<!-- <button type="button" class="btn btn-primary mb-2 mr-sm-2" @click="doFilter">Go</button> -->
						<!-- <button type="button" class="btn btn-danger mb-2 mr-sm-2" @click="resetFilter">Reset</button> -->
					</div>
				</div>
			</div>


			<!-- form modal input-->
		<modal name="inputReason"  :click-to-close="formClose">
			<div class=" " style="height:176px !important">
				<!-- <div class="col-md-12"> -->
					<div class="card">
						<div class="card-body">
							<h1 class="header-title">
								Form Input Reason
							</h1>

							<form @submit.prevent="inputData">
								<div class="form-row mt-3">
									<div class="col-md-12">
										<div class="form-group">
											<input v-model="dataNewReason.reason" type="text" class="form-control form-control-sm" placeholder="name" required>
										</div>
									</div>
									<div class="col-md-12 mt-1">
										<div class="float-right">
											<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
											<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormInput">Cancel</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				<!-- </div> -->
			</div>
		</modal>

		<!-- form modal edit-->
		<modal name="editReason"  :click-to-close="formClose">
			<div class=" " style="height:176px !important">
				<!-- <div class="col-md-12"> -->
					<div class="card">
						<div class="card-body">
							<h1 class="header-title">
								Form Edit Reason
							</h1>

							<form @submit.prevent="updateDate">
								<div class="form-row mt-3">
									<div class="col-md-12">
										<div class="form-group">
											<input v-model="dataReason.reason" type="text" class="form-control form-control-sm" placeholder="name" required>
										</div>
									</div>
									<div class="col-md-12 mt-1">
										<div class="float-right">
											<input type="submit" class="btn btn-create mr-1 btn-sm" value="Save" />
											<button type="button" class="btn btn-cancel btn-sm" @click.prevent="hideFormEdit">Cancel</button>
										</div>
									</div>
								</div>
							</form>
						</div>
					</div>
				<!-- </div> -->
			</div>
		</modal>

		</div>

		<!-- Table -->
		<div class="table-responsive">
			<vuetable ref="vuetable" :per-page="perPage" :fields="fields" :api-url="apiUrl" :api-mode="true" :http-options="httpOptions" :show-sort-icons="true" :append-params="moreParams" pagination-path class="table table-centered table-striped" data-path="data" @vuetable:loading="loadingTable" @vuetable:load-success="loadTableSuccess" @vuetable:load-error="loadTableFailed" @vuetable:pagination-data="onPaginationData">
				<template slot="actions" slot-scope="props">
					<div class="table-button-container">
						<a href="javascript:void(0);" class="action-icon text-white btn btn-sm mr-1" style="background: #51C1AD;" @click="editShow(props.rowData, props.rowIndex)">
							<i class="mdi mdi-square-edit-outline"></i>
						</a>
						<a href="javascript:void(0);" class="action-icon text-white btn btn-sm" style="background: #EA4C40;" @click="onAction('delete-item', props.rowData, props.rowIndex)">
							<i class="mdi mdi-delete"></i>
						</a>
					</div>
				</template>
			</vuetable>
		</div>
		<!-- end table -->

		<!-- pagination -->
		<div class="row">
			<div class="col-md-5">
				<div class="row no-gutters">
					<div class="col-lg-3">
						<div class="form-group float-lg-left ">
							<select v-model="perPage" class="form-control form-control-sm" @change="onChangeTableLength">
								<option v-for="opt in lengthMenu" :key="opt" :value="opt">
									{{opt}}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-8">

						<vuetable-pagination-info ref="paginationInfo" class="justify-content-center justify-content-lg-start text-center text-lg-left">
						</vuetable-pagination-info>
					</div>
				</div>


			</div>
			<div class="col-sm-12 col-md-7">
				<vuetable-pagination ref="pagination" :css="css.pagination" class="pagination pagination-rounded justify-content-center justify-content-lg-end" @vuetable-pagination:change-page="onChangePage">
				</vuetable-pagination>
			</div>
		</div>
		<!-- end pagination -->
	</div>
</template>
<script>
import Swal from 'sweetalert2'
	import Vuetable from 'vuetable-2/src/components/Vuetable'
	import VuetablePagination from 'vuetable-2/src/components/VuetablePagination'
	import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo'
	import {
		authMethods
	} from '@state/helpers'
	import {
		mapActions, mapState
	} from 'vuex'

	export default {
		components: {
			Vuetable,
			VuetablePagination,
			VuetablePaginationInfo,
		},
		props: {
			showActions: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				moreParams: {},
				filterText: '',
				deleteData: {},
				formClose: false,
				isLoadingTable: false,
				httpOptions: {
					headers: {
						Authorization: 'Bearer ' + localStorage.getItem('access_token')
					},
				},
				dataNewReason:{},
				apiUrl: this.$store.state.rootApi + '/reason',
				urlPdf: '#',
				items: [{
						text: 'Master',
						href: '/',
					},
					{
						text: 'Reason',
						href: '/reason',
					},
					{
						text: 'List',
						active: true,
					},
				],
				lengthMenu: [5, 10, 25, 50, 100],
				perPage: 5,
				fields: [{
						name: 'reason',
						title: 'Reason',
						sortField: 'reason',
					},
					{
						name: 'actions',
						title: 'Actions',
						titleClass: 'text-center',
						dataClass: 'text-center',
					},
				],
				css: {
					pagination: {
						activeClass: 'btn-primary text-white',
						pageClass: 'btn btn-sm ',
						linkClass: 'btn btn-sm',
						icons: {
							prev: 'fas fa-arrow-left',
							next: 'fas fa-arrow-right',
						},
					},
				},
			}
		},
			computed: {
				...mapState('masterReason', ['dataReason']),
			},
		mounted() {


		},

		methods: {
			...authMethods,
			loginByToken() {
				console.log('login by token')
			},

			...mapActions('masterReason', ['deleteReason','saveReason','getReason', 'updateReason']),
			showFormInput() {
				this.$modal.show('inputReason')
			},
			hideFormInput() {
				this.isLoadingTable = false
				this.$modal.hide('inputReason')
				this.dataNewReason = {}
			},
			editShow(rowData, rowIndex) {
				this.$modal.show('editReason')
				this.getReason(rowData.id)
					.then((res) => {})
					.catch((err) => {
						console.log(err)
						this.errorAlert()
					})
			},

			inputData() {

				this.saveReason({
						payload: this.dataNewReason,
					})
					.then((res) => {
						const Toast = Swal.mixin({
							toast: true,
							position: 'bottom-end',
							showConfirmButton: false,
							timer: 2000,
							timerProgressBar: true,
							onOpen: (toast) => {
								toast.addEventListener('mouseenter', Swal.stopTimer)
								toast.addEventListener('mouseleave', Swal.resumeTimer)
							}
						})

						Toast.fire({
							icon: 'success',
							title: 'successfully'
						})

						this.$modal.hide('inputReason')
						console.log(res)
						this.$refs.vuetable.refresh()
						this.dataNewReason = {}
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert('Failed save data')
					})
			},

			updateDate() {
				// update Reason
				// console.log(this.dataReason)
				this.updateReason({
						payload: this.dataReason,
						id: this.dataReason.id
					})
					.then((res) => {
						console.log(res)
						this.$refs.vuetable.refresh()
					})
					.catch((err) => {
						console.log(err)
						this.errorAlert('failed update data')
					})



				this.successAlert('Successfully')
				this.$modal.hide('editReason')


			},

			doFilter() {
				this.onFilterSet(this.filterText)
			},
			/**
			 * Table Length Change
			 *
			 * @return  {[type]}  [return description]
			 */
			onChangeTableLength() {
				// this.$refs.vuetable.reload()
				this.$nextTick(() => this.$refs.vuetable.refresh())
			},
			/**
			 * Pagination page change
			 */
			onChangePage(page) {
				this.$refs.vuetable.changePage(page)
			},
			/**
			 * Pagination info and pagination show
			 */
			onPaginationData(paginationData) {
				this.$refs.pagination.setPaginationData(paginationData)
				this.$refs.paginationInfo.setPaginationData(paginationData)
			},
			/**
			 * [onFilterSet description]
			 *
			 * @param   {[type]}  filterText  [filterText description]
			 *
			 * @return  {[type]}              [return description]
			 */
			onFilterSet(filterText) {
				this.moreParams = {
					search: filterText,
				}
				this.$nextTick(() => this.$refs.vuetable.refresh())
			},
			/**
			 * Show Table Loading
			 *
			 * @return  {[type]}  [return description]
			 */
			loadingTable() {
				this.isLoadingTable = true
			},

			/**
			 * Hide Loading if Success
			 *
			 * @return  {[type]}  [return description]
			 */
			loadTableSuccess() {
				this.isLoadingTable = false
			},
			/**
			 * Hide Loading if Failed
			 *
			 * @return  {[type]}  [return description]
			 */
			loadTableFailed(error) {
				console.log(error)

				this.isLoadingTable = false
				this.$swal({
					title: 'Terjadi Kesalahan',
					type: 'error',
					timer: '2000',
					toast: true,
					position: 'bottom-end',
				})
				// }
			},


			onAction(action, rowData, rowIndex) {
				if (action === 'edit-item') {
					this.$router.push({
						name: 'Reason Edit',
						params: {
							id: rowData.id,
						},
					})
				}
				else if (action === 'delete-item') {
					this.showConfirmDelete(rowData.id)

				}
			},

			showConfirmDelete(id) {
				this.deleteData.id = id
				let self = this
				// this.$swal({
				// 	title: 'Are you sure?',
				// 	text: 'You will not be able to recover this!',
				// 	type: 'warning',
				// 	showCancelButton: true,
				// 	confirmButtonText: 'Yes, delete it!',
				// 	cancelButtonText: 'No, keep it',
				// }).then((result) => {
				// 	if (result.value) {
				// 		this.$swal('Deleted!', 'Your data has been deleted.', 'error')
				// 		self.onDelete()
				// 	} else if (result.dismiss) {
				// 		this.$swal('Cancelled', 'Your data is safe :)', 'error')
				// 	}
				// })
				Swal.fire({
					title: 'Are you sure?',
					text: "You will not be able to recover this data!",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: 'rgb(221, 107, 85)',
					cancelButtonColor: '#C1C1C1',
					reverseButtons: true,
					confirmButtonText: 'Yes, delete it!'
				}).then((result) => {
					if (result.isConfirmed) {
						self.onDelete()
						Swal.fire(
							'Deleted!',
							'Your file has been deleted.',
							'success'
						)
					}
				})
			},
			/**
			 * Delete Brand
			 *
			 * @return  {[type]}  [return description]
			 */
			onDelete() {
				// this.isLoadingTable = true
				this.deleteReason(this.deleteData.id)
					.then((res) => {
						this.successAlert()
						// this.isLoadingTable = false;
						this.$refs.vuetable.refresh()
					})
					.catch((err) => {
						this.errorAlert()
						console.log(err.response.message)

						// this.isLoadingTable = false;
					})
			},
		}

	}

</script>
